import ContactMe from "./../../home/ContactMe";

export default function NOTDSupport({ contactMeRef }) {
  return (
    <>
      <div className="flex bg-zinc-800">
        <div className="pb-16 mx-auto container pt-3 px-6 max-w-screen-md">
          <h1 className="blog-heading">News Of The Dai</h1>
          <h1 className="blog-subheading">Support Information</h1>
          <p className="regular-paragraph">
            Thanks for downloading News Of The Dai!
          </p>
          <p className="regular-paragraph">
            If you are having any problems with the app, or want to make any
            suggestions for updates, please email me at{" "}
            <a
              href="mailto: hello@olivertoohey.com"
              className="text-white font-bold hover:text-yellow-100"
            >
              hello@olivertoohey.com
            </a>
            .
          </p>
          <p className="regular-paragraph">
            Please keep in mind that the content is generated by AI and
            therefore can sometimes be unpredictable!
          </p>
          <p className="regular-paragraph">
            The owners of the articles the app generates content from has no
            affiliation with the app, nor does the developer or owners of the
            articles take any responsiblity for the content generated.
          </p>
          <br></br>
          <p className="regular-paragraph text-5xl">
            <b>Privacy Policy</b>
          </p>
          <p className="regular-paragraph">
            Oliver Toohey built the News Of The Dai app as a Free app. This
            SERVICE is provided by Oliver Toohey at no cost and is intended for
            use as is.
          </p>
          <p className="regular-paragraph">
            This page is used to inform visitors regarding my policies with the
            collection, use, and disclosure of Personal Information if anyone
            decided to use my Service.
          </p>
          <p className="regular-paragraph">
            If you choose to use my Service, then you agree to the collection
            and use of information in relation to this policy. The Personal
            Information that I collect is used for providing and improving the
            Service. I will not use or share your information with anyone except
            as described in this Privacy Policy.
          </p>
          <p className="regular-paragraph">
            The terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions, which are accessible at News Of The Dai
            unless otherwise defined in this Privacy Policy.
          </p>
          <p className="regular-paragraph text-xl pb-0">
            <b>Information Collection and User</b>
          </p>
          <p className="regular-paragraph">
            For a better experience, while using our Service, I may require you
            to provide us with certain personally identifiable information. The
            information that I request will be retained on your device and is
            not collected by me in any way.
          </p>
          <p className="regular-paragraph text-xl pb-0">
            <b>Log Data</b>
          </p>
          <p className="regular-paragraph">
            I want to inform you that whenever you use my Service, in a case of
            an error in the app I collect data and information (through
            third-party products) on your phone called Log Data. This Log Data
            may include information such as your device Internet Protocol (“IP”)
            address, device name, operating system version, the configuration of
            the app when utilizing my Service, the time and date of your use of
            the Service, and other statistics.
          </p>
          <p className="regular-paragraph text-xl pb-0">
            <b>Security</b>
          </p>
          <p className="regular-paragraph">
            I value your trust in providing us your Personal Information, thus
            we are striving to use commercially acceptable means of protecting
            it. But remember that no method of transmission over the internet,
            or method of electronic storage is 100% secure and reliable, and I
            cannot guarantee its absolute security.
          </p>
          <p className="regular-paragraph text-xl pb-0">
            <b>Links to Other Sites</b>
          </p>
          <p className="regular-paragraph">
            This Service may contain links to other sites. If you click on a
            third-party link, you will be directed to that site. Note that these
            external sites are not operated by me. Therefore, I strongly advise
            you to review the Privacy Policy of these websites. I have no
            control over and assume no responsibility for the content, privacy
            policies, or practices of any third-party sites or services.
          </p>
          <p className="regular-paragraph text-xl pb-0">
            <b>Children's Privacy</b>
          </p>
          <p className="regular-paragraph">
            I do not knowingly collect personally identifiable information from
            children. I encourage all children to never submit any personally
            identifiable information through the Application and/or Services. I
            encourage parents and legal guardians to monitor their children's
            Internet usage and to help enforce this Policy by instructing their
            children never to provide personally identifiable information
            through the Application and/or Services without their permission. If
            you have reason to believe that a child has provided personally
            identifiable information to us through the Application and/or
            Services, please contact us. You must also be at least 16 years of
            age to consent to the processing of your personally identifiable
            information in your country (in some countries we may allow your
            parent or guardian to do so on your behalf).
          </p>
          <p className="regular-paragraph text-xl pb-0">
            <b>Changes to This Privacy Policy</b>
          </p>
          <p className="regular-paragraph">
            I may update our Privacy Policy from time to time. Thus, you are
            advised to review this page periodically for any changes. I will
            notify you of any changes by posting the new Privacy Policy on this
            page. This policy is effective as of 2023-06-23
          </p>
        </div>
      </div>
      <ContactMe contactMeRef={contactMeRef} />
    </>
  );
}
